import { PageController } from '@sitecore-search/react';

type UserGoalProps = Array<string>;

const setUserGoals = (updatedUserGoals: UserGoalProps) => {
  if (updatedUserGoals) {
    // uncomment when the OrderCloud 400 issue is fixed and allows to use an array instead of string value
    // PageController.getContext().setUserCustom({ goal: updatedUserGoals });
    PageController.getContext().setUserCustom({ goal: updatedUserGoals.toString() });
  }
};

export default setUserGoals;

//import { ApiRole } from 'ordercloud-javascript-sdk';
import { OcConfig } from 'src/redux/ocConfig';

export const getOcConfig = (sitename: string) => {
  // OC Integration:
  const sitenameUppercase = sitename?.toUpperCase() as string;

  // sample data just to populate
  let ocConfigArray: OcConfig = {
    clientId: '',
    scope: [''],
    baseApiUrl: '',
    allowAnonymous: true,
    isPreviewing: true,
  };

  // Switching for multi-theming:
  switch (sitenameUppercase) {
    case 'PSP':
      /*  const configeredScope: string[] | ApiRole[] | undefined =
        process.env.NEXT_PUBLIC_PSP_OC_SCOPE?.split(',')
          .map((role) => role.trim())
          .sort(); */

      ocConfigArray = {
        clientId: process.env.NEXT_PUBLIC_PSP_OC_CLIENT_ID || '12',
        scope: [''],
        baseApiUrl: process.env.NEXT_PUBLIC_PSP_OC_BASE_API_URL || 'undefined',
        allowAnonymous: process.env.NEXT_PUBLIC_PSP_OC_ALLOW_ANONYMOUS === 'true',
        isPreviewing:
          Boolean(process.env.EXPERIENCE_EDITOR_MODE) ||
          true /* Whether or not this is being rendered in xm experience editor*/,
      };
      break;

    case 'WAGNWASH':
      /*       const configeredWNWScope: string[] | ApiRole[] | undefined =
        process.env.NEXT_PUBLIC_WNW_OC_SCOPE?.split(',')
          .map((role) => role.trim())
          .sort(); */

      ocConfigArray = {
        clientId: process.env.NEXT_PUBLIC_WNW_OC_CLIENT_ID || '12',
        scope: [''],
        baseApiUrl: process.env.NEXT_PUBLIC_WNW_OC_BASE_API_URL || 'undefined',
        allowAnonymous: process.env.NEXT_PUBLIC_WNW_OC_ALLOW_ANONYMOUS === 'true',
        isPreviewing:
          Boolean(process.env.EXPERIENCE_EDITOR_MODE) ||
          true /* Whether or not this is being rendered in xm experience editor*/,
      };
      break;

    default:
      break;
  }

  return ocConfigArray;
};

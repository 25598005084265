import { PageController } from '@sitecore-search/react';

type UserGroupProps = Array<string>;

const setUserGroups = (updatedUserGroups: UserGroupProps) => {
  if (updatedUserGroups) {
    PageController.getContext().setUserGroups(updatedUserGroups);
  }
};

export default setUserGroups;

import React from 'react';
import { isEqual } from 'lodash';
import { FunctionComponent, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { initializeAuth } from './ocAuth';
import logout from './ocAuth/logout';
import { OcConfig, setConfig } from './ocConfig';
import { retrieveOrder } from './ocCurrentOrder';
import ocStore, { useOcDispatch, useOcSelector } from './ocStore';
import { getUser } from './ocUser';
import { useRouter } from 'next/router';
import authAnonymous from './ocAuth/authAnonymous';
import { useCookies } from 'react-cookie';
import { getLookup } from './lookupSlice';
import { getConfiguration } from './configurationSlice';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { setGlobalStoreSettings, setStore } from './storeDetailsSlice';
import { getDefaultStore, getGlobalStoreSettings, updateMyStore } from 'src/helpers/StoreHelper';
import { getWishlist, setWishlistCollectionId } from './wishlist';
import { setGender, setSpecies } from './myPetsSlice';
import { setPetsGender, setPetsSpecies } from 'src/helpers/MyPetsHelper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { fetchOtherSetting } from './otherSettingSlice';
import { useTheme } from 'lib/context/ThemeContext';
import { SiteName } from 'components/helpers/Constants';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { getSiteName } from 'src/helpers/siteNameHelper';

interface OcProviderProps extends React.PropsWithChildren {
  config: OcConfig;
  pageState?: string | undefined;
}

type OcGlobalDataProps = React.PropsWithChildren;

const OcInitializer: FunctionComponent<OcProviderProps> = ({
  children,
  config,
  // isPreviewMode,
  // pageState,
}) => {
  const dispatch = useOcDispatch();
  const isPreviewing = config.isPreviewing;

  // Using separate selectors to avoid issue with creating a new object in selector
  // which causes performance issues.  https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const ocConfig = useOcSelector((s) => s.ocConfig);
  const ocAuth = useOcSelector((s) => s.ocAuth);
  const ocUser = useOcSelector((s) => s.ocUser);
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const router = useRouter();
  const [, setCookie] = useCookies(['storeId', 'storeDetails']);

  useEffect(() => {
    const fetchOcData = async () => {
      if (!ocConfig?.value || !isEqual(ocConfig?.value, config)) {
        await dispatch(setConfig(config));
      } else if (!ocAuth?.initialized) {
        await dispatch(initializeAuth());
      } else if (!ocAuth?.isAuthenticated && ocUser.user) {
        await dispatch(logout());
      } else if (!ocAuth?.isAuthenticated) {
        await dispatch(authAnonymous());
      } else if (ocAuth?.isAuthenticated) {
        if (!ocUser.user && !ocUser.loading) {
          await dispatch(getUser());
        }
        // !Important Note: MystoreData?storeId is not available then we are not calling the retrieveOrder.
        // if (myStoreData?.storeId && !ocCurrentOrder.initialized) {
        if (myStoreData?.storeId && ocCurrentOrder.initialized == null) {
          await dispatch(retrieveOrder());
        }
      }
    };

    fetchOcData();
  }, [
    dispatch,
    config,
    ocConfig,
    ocAuth,
    ocUser,
    ocCurrentOrder,
    isPreviewing,
    router,
    setCookie,
    myStoreData?.storeId,
  ]);

  /**
   * Sending GTM data for loggedIn/non-loggedIn user
   */

  // TODO: will need to come with other options as well.
  // if (!isEE && !isPreviewMode) {
  //   if (!ocAuth?.isAuthenticated) {
  //     return <>Loading.....</>;
  //   }
  // }
  // if (pageState && pageState === 'normal') {
  //   // This will omit the check in the `editor` and `preview` sitecore mode
  //   if (!ocAuth.isAuthenticated) {
  //     return <>Loading.....</>;
  //   }
  // }
  return <>{children}</>;
};

// For setting and managing global data of project.
const OcGlobalData: FunctionComponent<OcGlobalDataProps> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const wishlist = useOcSelector((s) => s.wishlist);
  const ocUser = useOcSelector((s) => s.ocUser);
  const isAnonymous = useOcSelector((s) => s.ocAuth?.isAnonymous);
  const { themeNameUpper } = useTheme();
  const context = useSitecoreContext();
  const sitecoreContext = context.sitecoreContext;
  const siteName = sitecoreContext?.site?.name ?? 'unknown';
  const language = 'en';

  /**
   * Wishlist SDK call based on user wishlist
   */
  useEffect(() => {
    if (!isAnonymous && !wishlist?.wishlistCollectionId && ocUser?.user?.ID) {
      dispatch(setWishlistCollectionId(`Wishlist-${ocUser?.user?.ID}`));
    } else if (
      wishlist?.wishlistCollectionId &&
      !isAnonymous && // Added condition to avoid wishlist call at time of logout and for guest user.
      ocUser?.user?.ID
    ) {
      dispatch(getWishlist(`Wishlist-${ocUser?.user?.ID}`));
    }
  }, [ocUser?.user?.ID, isAnonymous, wishlist?.wishlistCollectionId]);

  /**
   * Get Store details based on user store.
   */
  useEffect(() => {
    const getDetails = async (storeId?: string) => {
      if (storeId) {
        return await dispatch(setStore(await updateMyStore(storeId, siteName)));
      } else {
        return await dispatch(setStore(await getDefaultStore(siteName)));
      }
    };
    const storeId = localStorage.getItem('storeId');
    if (storeId && !myStoreData?.storeId) {
      getDetails(storeId);
    } else if (!myStoreData?.storeId) {
      getDetails();
    }
  }, [myStoreData?.storeId, siteName]);

  /**
   * Get global settingsm, store settings, lookups
   */
  useEffect(() => {
    const getStoreSettings = async () => {
      dispatch(setGlobalStoreSettings(await getGlobalStoreSettings(siteName)));
    };
    dispatch(getLookup(siteName as SiteName));
    dispatch(getConfiguration() as unknown as AnyAction);
    getStoreSettings();
  }, [siteName]);

  /**
   * Fetching Pets Gender Details
   */
  const token = useAuthToken();

  useEffect(() => {
    const getPetDetails = async () => {
      !isAnonymous &&
        dispatch(setGender(await setPetsGender(token, getSiteName(siteName)?.toUpperCase())));
      !isAnonymous &&
        dispatch(setSpecies(await setPetsSpecies(token, getSiteName(siteName)?.toUpperCase())));
      dispatch(fetchOtherSetting({ language: language, siteName: siteName }));
    };
    getPetDetails();
  }, [siteName, themeNameUpper, isAnonymous]);

  return <>{children}</>;
};

const OcProvider: FunctionComponent<OcProviderProps> = ({ children, config, pageState }) => {
  return (
    <>
      <Provider store={ocStore}>
        <OcInitializer config={config} pageState={pageState}>
          <OcGlobalData>{children}</OcGlobalData>
        </OcInitializer>
      </Provider>
    </>
  );
};

export default OcProvider;

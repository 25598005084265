import { PageController } from '@sitecore-search/react';

type StoreEntityProps = string | Array<string>;

const setStoreEntity = (updatedStoreEntity: StoreEntityProps) => {
  if (updatedStoreEntity) {
    const entityIds = Array.isArray(updatedStoreEntity) ? updatedStoreEntity : [updatedStoreEntity];
    PageController.getContext().setEntityIds('store', entityIds);
  }
};

export default setStoreEntity;

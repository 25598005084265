import { PageController } from '@sitecore-search/react';

type StoreModelType = {
  id?: string;
  groupId?: string;
};

const setGroupStoreId = (storeId: string, isAnonymous: boolean) => {
  const groupIdValue = isAnonymous === false ? 'PSP_Buyer' : 'AnonymousGroup';

  const storeDetails: StoreModelType = {
    id: storeId,
    groupId: groupIdValue,
  };

  if (storeDetails.id) {
    PageController.getContext().setStoreId(storeDetails.id);
  }

  if (storeDetails.groupId) {
    PageController.getContext().setStoreGroupId(storeDetails.groupId);
  }
};

export default setGroupStoreId;
